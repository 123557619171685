import React, { Suspense, useState, useEffect } from "react";
import {
  ApolloClient,
  ApolloProvider,
  InMemoryCache,
  HttpLink
} from '@apollo/client';
import {
  Route,
  Switch,
  BrowserRouter as Router,
  withRouter,
} from "react-router-dom";

import config from "./config";
import routes from "./routes";
import Layout from "./components/Layout/";
import Loader from "./components/Shared/Loader";
import ScrollFixToTop from "./components/Shared/ScrollFixToTop";

import "./Apps.scss";
import "./assets/css/colors/default.css";
import "./assets/css/materialdesignicons.min.css";


import { withLocalize } from "react-localize-redux";
import { renderToStaticMarkup } from "react-dom/server";
import globalTranslations from "./locales/global.json";


import "bootstrap/dist/css/bootstrap.min.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../node_modules/slick-carousel/slick/slick-theme.css"
import "../node_modules/react-modal-video/scss/modal-video.scss";

import icLogo from './assets/images/ic_logo_text.png'
import { useQuery } from '@apollo/client';
import { GET_ROOMTYPE_BY_IDS } from './gql/roomType';
import { NotifyContainer, notifyError } from "./components/Shared/Notify";
import image_placeholder from "./assets/images/image_placeholder.png";

const TenantGroupIndex = React.lazy(() => import("./pages/TenantGroupIndex"));
const TenantIndex = React.lazy(() => import("./pages/TenantIndex"));
const RoomDetail = React.lazy(() => import("./pages/RoomDetail"));
const Payment = React.lazy(() => import("./pages/Payment"));
const BookingConfirm = React.lazy(() => import("./pages/BookingConfirm"));


const createApolloClient = () => {
  return new ApolloClient({
    link: new HttpLink({
      uri: `https://${config.hasuraHost}`,
    }),
    cache: new InMemoryCache(),
  });
};

function App(props) {
  useEffect(async () => {
    props.initialize({
      languages: [
        { name: "English", code: "en" },
        { name: "Bahasa", code: "id" },
      ],
      translation: globalTranslations,
      options: {
        renderToStaticMarkup,
        renderInnerHtml: false,
        defaultLanguage: "en",
        ignoreTranslateChildren: false,
      },
    });
    setIsLoading(true);
    await getIbeFromWeburl();

  }, [])

  async function getIbeFromWeburl() {
    //let namespace = "balisuperhost";
    let namespace = window.location.host.split(".")[0];

    const endpoint = `https://${config.hasuraHost}`;
    const headers = {
      "content-type": "application/json",
    };
    const graphqlQuery = {
      "operationName": "MyQuery3",
      "query": `query MyQuery3 {\n  ibe_sites(where: {namespace: {_eq: \"${namespace}\"}}) {\n    banner_content_1\n    banner_content_2\n    banner_content_3\n    banner_img_url_1\n    banner_img_url_2\n    banner_img_url_3\n    banner_title_1\n    banner_title_2\n    banner_title_3\n    fb_link\n    id\n    insta_link\n    is_tenant_group\n    logo_url\n    name\n    namespace\n    tenant_group_id\n    tenant_id\n  }\n}\n`,
      "variables": {}
    };

    const options = {
      "method": "POST",
      "headers": headers,
      "body": JSON.stringify(graphqlQuery)
    };

    const response = await fetch(endpoint, options);
    const data = await response.json();
    const ibeData = data && data.data && data.data.ibe_sites && data.data.ibe_sites[0];
    if (ibeData != null) {
      if (ibeData.is_tenant_group) {
        //tenant group
        getGroupFromGroupId(ibeData.name, ibeData.tenant_group_id);
      } else {
        //tenant
        let tenantResult = {
          bannerTitle1: ibeData.banner_title_1,
          bannerContent1: ibeData.banner_content_1,
          bannerImg1: ibeData.banner_img_url_1,
          bannerTitle2: ibeData.banner_title_2,
          bannerContent2: ibeData.banner_content_2,
          bannerImg2: ibeData.banner_img_url_2,
          bannerTitle3: ibeData.banner_title_3,
          bannerContent3: ibeData.banner_content_3,
          bannerImg3: ibeData.banner_img_url_3,
          fbLink: ibeData.fb_link,
          instaLink: ibeData.insta_link,
          logo: ibeData.logo_url,
          name: ibeData.name,
          id: ibeData.tenant_id
        }
        setIsTenantGroup(false);
        setTenantData(tenantResult);
        setIsLoading(false);
      }
    }
  }

  async function getGroupFromGroupId(groupName, groupId) {
    const endpoint = `https://${config.hasuraHost}`;
    const headers = {
      "content-type": "application/json",
    };
    const graphqlQuery = {
      "operationName": "MyQuery3",
      "query": `query MyQuery3 {\n  ibe_sites(where: {tenant_group_id: {_eq: \"${groupId}\"}, is_tenant_group: {_eq: false}}) {\n    banner_content_1\n    banner_content_2\n    banner_content_3\n    banner_img_url_1\n    banner_img_url_2\n    banner_img_url_3\n    banner_title_1\n    banner_title_2\n    banner_title_3\n    fb_link\n    id\n    insta_link\n    is_tenant_group\n    logo_url\n    name\n    namespace\n    tenant_group_id\n    tenant_id\n    tenant {\n      address_1\n      address_2\n      region\n      phone_no\n      zip_code\n      country\n      city\n      e_country {\n        comment\n      }\n    }\n  }\n}\n`,
      "variables": {}
    };

    const options = {
      "method": "POST",
      "headers": headers,
      "body": JSON.stringify(graphqlQuery)
    };

    const response = await fetch(endpoint, options);
    const data = await response.json();

    const ibeData = data && data.data && data.data.ibe_sites;
    let groupResult = {
      name: groupName,
      tenants: []
    }

    ibeData.forEach(element => {
      groupResult.tenants.push({
        name: element.name,
        logo_url: element.logo_url,
        address1: element.tenant.address_1,
        address2: element.tenant.address_2,
        region: element.tenant.region,
        phoneNo: element.tenant.phone_no,
        zipCode: element.tenant.zip_code,
        country: element.tenant.e_country.comment,
        city: element.tenant.city,
      })
    });

    setIsTenantGroup(true);
    setTenantGroupData(groupResult);
    setIsLoading(false);
  }

  const [client] = useState(createApolloClient());
  const [isLoading, setIsLoading] = useState(false);
  const [isTenantGroup, setIsTenantGroup] = useState(false);
  const [tenantGroupData, setTenantGroupData] = useState({
    name: "",
    tenants: []
  })
  const [tenantData, setTenantData] = useState({
    bannerTitle1: "",
    bannerContent1: "",
    bannerImg1: "",
    bannerTitle2: "",
    bannerContent2: "",
    bannerImg2: "",
    bannerTitle3: "",
    bannerContent3: "",
    bannerImg3: "",
    fbLink: "",
    instaLink: "",
    logo: "",
    name: "",
    id: ""
  });

  if (isLoading) return <div style={{ position: "absolute", marginLeft: "50vw", marginTop: "50vh" }}><img src={icLogo} style={{ marginTop: -100, width: 200, display: "block", marginBottom: 50, marginLeft: -75 }} /><div style={{ marginTop: 10, marginLeft: -10, fontWeight: "bold" }}>Loading</div></div>;
  return (
    <>
      <Router>
        <ScrollFixToTop>
          <Suspense fallback={<Loader />}>
            <Switch>
              <ApolloProvider client={client}>
                <Route exact path="/" render={(props) => isTenantGroup ?
                  <TenantGroupIndex {...props} tenantGroupData={tenantGroupData} />
                  : <Layout hasDarkTopBar={false} tenantData={tenantData}><TenantIndex {...props} tenantData={tenantData} /></Layout>} />
                <Route path="/room-type" render={(props) => <Layout hasDarkTopBar={false} tenantData={tenantData}><RoomDetail {...props} tenantData={tenantData} /></Layout>} />
                <Route path="/payment" render={(props) => <Layout hasDarkTopBar={false} tenantData={tenantData}><Payment {...props} tenantData={tenantData} /></Layout>} />
                <Route path="/booking-confirmation" render={(props) => <Layout hasDarkTopBar={false} tenantData={tenantData}><BookingConfirm {...props} tenantData={tenantData} /></Layout>} />
              </ApolloProvider>
            </Switch>
          </Suspense>
        </ScrollFixToTop>
      </Router>
    </>
  );
}

export default withLocalize(withRouter(App));